import React from 'react';
import Reservation from '../../Reservation/Reservation';

export default class Calendar extends React.Component<any>{
    public render() {
      return (
        <div>
          <Reservation></Reservation>
        </div>
        );
    }
}