export enum ImageTypeEnum {
    'RedRoomPicture' = 0,
    'BlueRoomPicture' = 1,
    'GrayRoomPicture' = 2,

    'StudioPicture' = 3,
    'AboutPagePicture' = 4,

    'RoomsPicture' = 5,
    'EventsPicture' = 6,
    'RentsPicture' = 7,
  }
  